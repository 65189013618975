<template>
  <div class="container">
    <PublicHeader :totalName="$t('msgbank').msgTitle" />
    <div class="content">
      <van-list
        class="list-box"
        ref="list"
        v-model="loading"
        :finished="finished"
        :loading-text="$t('public').loadingText"
        :finished-text="$t('public').noMoreData"
        @load="getList"
      >
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="msg">{{ item.content }}</div>
          <div class="dataTime">{{ item.CreateTime | getLocalTime }}</div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeaderNew'
import userAPI from '@/api/user'
export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      list: [],
      form: {
        page: 1,
        size: 10
      },
      loading: false,
      finished: false
    }
  },
  methods: {
    async getList() {
      //全部消息已读
      await userAPI.upUserMessageRead()
      const res = await userAPI.getUserMessageList(this.form)
      let data = res.Data
      let length = data.length
      if (length != 0) {
        this.list.push.apply(this.list, data)
        this.form.page++
      }
      if (length !== this.form.size) {
        this.finished = true
      }
      if (this.refreshing) {
        this.refreshing = false
      }
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding-top: 44px;
  .content {
    margin: 10px;
    border-radius: 4px;
    overflow: hidden;
    .list-box {
      height: calc(100vh - 64px);
      overflow: scroll;
    }
    .item {
      border-radius: 5px;
      background: #17181e;
      padding: 10px;
      margin-bottom: 10px;
      .msg {
        font-size: 16px;
        margin-bottom: 5px;
        line-height: 25px;
      }
      .dataTime {
        margin-top: 5px;
        color: #d5d2d2;
      }
    }
  }
}
</style>
